// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/uc0aKKpYI";
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-fu61T"

const variantClassNames = {DZe15Pgzm: "framer-v-h04wsn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: -150}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: -150}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color, height, id, title, width, ...props}) => { return {...props, mUDbpOXDv: color ?? props.mUDbpOXDv ?? "var(--token-93aecf4f-3b8c-4948-a3de-b4ec2780b5b0, rgb(234, 181, 46))", sH_drRyxa: title ?? props.sH_drRyxa ?? "Wonder"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sH_drRyxa, mUDbpOXDv, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "DZe15Pgzm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-h04wsn", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DZe15Pgzm"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-orqdza"} data-styles-preset={"uc0aKKpYI"} style={{"--framer-text-color": "var(--extracted-1of0zx5, var(--variable-reference-mUDbpOXDv-orVoAah_F))"}}>Wonder</motion.h2></React.Fragment>} className={"framer-zcov4m"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ZV6eekphI"} style={{"--extracted-1of0zx5": "var(--variable-reference-mUDbpOXDv-orVoAah_F)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-mUDbpOXDv-orVoAah_F": mUDbpOXDv}} text={sH_drRyxa} verticalAlignment={"top"} withExternalLayout/></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fu61T.framer-1u000jk, .framer-fu61T .framer-1u000jk { display: block; }", ".framer-fu61T.framer-h04wsn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 4px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-fu61T .framer-zcov4m { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fu61T.framer-h04wsn { gap: 0px; } .framer-fu61T.framer-h04wsn > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-fu61T.framer-h04wsn > :first-child { margin-left: 0px; } .framer-fu61T.framer-h04wsn > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"sH_drRyxa":"title","mUDbpOXDv":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerorVoAah_F: React.ComponentType<Props> = withCSS(Component, css, "framer-fu61T") as typeof Component;
export default FramerorVoAah_F;

FramerorVoAah_F.displayName = "strat cycle title";

FramerorVoAah_F.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerorVoAah_F, {sH_drRyxa: {defaultValue: "Wonder", displayTextArea: false, title: "Title", type: ControlType.String}, mUDbpOXDv: {defaultValue: "var(--token-93aecf4f-3b8c-4948-a3de-b4ec2780b5b0, rgb(234, 181, 46))", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerorVoAah_F, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})